import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faBrain, faCode, faBezierCurve } from '@fortawesome/free-solid-svg-icons';

import BG_VIDEO from './tmp.mp4';
import LOGO from './logo.png';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  icon: {
    margin: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
  videoContainer: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100vh', 
    overflow: 'hidden',
    zIndex: -1,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
  },
  bgVideo: {
    /* Make video to at least 100% wide and tall */
    minWidth: '100%', 
    minHeight: '100%',
    
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: 'auto',
    height: 'auto',
    
    /* Center the video */
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    opacity: 0.4
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  logo: {
    width: '75%',
    maxWidth: 350,
    backdropFilter: 'blur(5px)',
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('sm')]: {
      backdropFilter: 'none'
    },
  },
  titleContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  subTitleContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    alignItems: 'center'
  },
  itemInnerContainer: {
    width: 'calc(100% - 3px)',
    padding: theme.spacing(2),
    backdropFilter: 'blur(5px)',
    [theme.breakpoints.down('sm')]: {
      backdropFilter: 'none'
    },
  },
  itemContainer: {
    color: 'inherit',
    textDecoration: 'none',
    cursor: 'pointer',
    height: '100%',
    border: '1px solid white',
    textAlign: 'center',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '200ms',
    '&:hover': {
      backdropFilter: 'blur(5px)',
      color: 'rgb(255,255,255)',
      border: '1px solid rgba(88,50,203, 0.5)',
      backgroundColor: 'rgba(88,50,203, 0.2)',
      transition: '500ms',
      "& *": {
        backdropFilter: 'none'
      }
    }
  },

}));

export default _ => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.videoContainer}>
        <video className={classes.bgVideo} loop autoPlay muted>
            <source src={BG_VIDEO} />
        </video>
      </div>
      <Grid container>
        <Grid item xs={12}>
            <div className={classes.titleContainer}>
                <img src={LOGO} alt="CKX Logo" className={classes.logo}/>
            </div>
        </Grid>
        <Grid item xs={12}>
            <div className={classes.subTitleContainer}>
              <div className={classes.itemInnerContainer}>
                <Typography variant="h4">Bespoke Software Solutions For The Globally Ambitious</Typography>
              </div>
            </div>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <a href="#what-is" className={classes.itemContainer}>
              <div className={classes.itemInnerContainer}>
                <FontAwesomeIcon icon={faLink} style={{ fontSize: 25,marginBottom: '1rem' }}/>
                <Typography variant="h5">What is CKX?</Typography>
              </div>
            </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <a href="#why-us" className={classes.itemContainer}>
              <div className={classes.itemInnerContainer}>
                <FontAwesomeIcon icon={faBrain} style={{ fontSize: 25,marginBottom: '1rem' }}/>
                <Typography variant="h5">Why Us?</Typography>
              </div>
            </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <a href="#case-studies" className={classes.itemContainer}>
              <div className={classes.itemInnerContainer}>
                <FontAwesomeIcon icon={faCode} style={{ fontSize: 25,marginBottom: '1rem' }}/>
                <Typography variant="h5">Case Studies</Typography>
              </div>
            </a>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
            <a href="#contact-us" className={classes.itemContainer}>
              <div className={classes.itemInnerContainer}>
                <FontAwesomeIcon icon={faBezierCurve} style={{ fontSize: 25,marginBottom: '1rem' }}/>
                <Typography variant="h5">Contact CKX</Typography>
              </div>
            </a>
        </Grid>
      </Grid>
    </div>
  );
}