import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import Home from './pages/home';
import WhatIs from './pages/whatIs';
import WhyUs from './pages/whyUs';
import CaseStudies from './pages/caseStudies';
import ContactUs from './pages/contactUs';
import Navigation from './components/navigation';

import './App.css';

const theme = createMuiTheme({
  palette: {
    text: {
      primary: "rgb(88, 89, 91, .87)"
    }
  }
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 2024,
    margin: '0 auto'
  }
}));

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Home />
        <WhatIs />
        <WhyUs />
        <CaseStudies />
        <ContactUs />
      </div>
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
