import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import Grow from '@material-ui/core/Grow';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import CloudQueueIcon from '@material-ui/icons/CloudQueue';
import LoopIcon from '@material-ui/icons/Loop';
import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export default ({onChange, isVisible}) => {
  const classes = useStyles();

  const [ai, setAi] = React.useState(true);
  const [serverless, setServerless] = React.useState(true);
  const [blockchain, setBlockchain] = React.useState(true);
  const [iot, setIot] = React.useState(true);

  const handleClick = (service) => {
    let rtn = {ai, serverless, blockchain, iot};
    rtn[service] = !rtn[service]
    switch(service) {
        case "ai":
            setAi(!ai);
            break;
        case "serverless":
            setServerless(!serverless);
            break;
        case "blockchain":
            setBlockchain(!blockchain);
            break;
        case "iot":
            setIot(!iot);
            break;
        default:
            break;
    }
    onChange(rtn)
  };

  return (
    <div className={classes.root}>
      <Grow in={isVisible} style={{ transitionDelay: isVisible ? '700ms' : '0ms' }}>
        <div>
          <Chip
            icon={<CodeIcon />}
            label="AI"
            clickable
            color={ai ? "primary" : "secondary"}
            onClick={_ => handleClick('ai')}
            onDelete={_ => handleClick('ai')}
            deleteIcon={ai ? <DoneIcon /> : null}
          />
        </div>
      </Grow>
      <Grow in={isVisible} style={{ transitionDelay: isVisible ? '800ms' : '0ms' }}>
        <div>
          <Chip
            icon={<CloudQueueIcon />}
            label="Serverless"
            clickable
            color={serverless ? "primary" : "secondary"}
            onClick={_ => handleClick('serverless')}
            onDelete={_ => handleClick('serverless')}
            deleteIcon={serverless ? <DoneIcon /> : null}
          />
        </div>
      </Grow>
      <Grow in={isVisible} style={{ transitionDelay: isVisible ? '900ms' : '0ms' }}>
        <div>
          <Chip
            icon={<LoopIcon />}
            label="Blockchain"
            clickable
            color={blockchain ? "primary" : "secondary"}
            onClick={_ => handleClick('blockchain')}
            onDelete={_ => handleClick('blockchain')}
            deleteIcon={blockchain ? <DoneIcon /> : null}
          />
        </div>
      </Grow>
      <Grow in={isVisible} style={{ transitionDelay: isVisible ? '1000ms' : '0ms' }}>
        <div>
          <Chip
            icon={<CenterFocusWeakIcon />}
            label="IoT"
            clickable
            color={iot ? "primary" : "secondary"}
            onClick={_ => handleClick('iot')}
            onDelete={_ => handleClick('iot')}
            deleteIcon={iot ? <DoneIcon /> : null}
          />
        </div>
      </Grow>
    </div>
  );
}
