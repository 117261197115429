import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';

const MAX_DESC_LENGTH = 20;

const useStyles = makeStyles(theme => ({
  link: {
    textDecoration: 'none'
  },
  card: {
    width: 275,
    height: 275,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  title: {
    height: 65,
    textAlign: 'center'
  },
  desc: {
    flexGrow: 1
  },
  tagContainer: {

  },
  tag: {
    marginRight: theme.spacing(1)
  }
}));

export default ({title, tags, id, description}) => {
  const classes = useStyles();

  let desc = description;
  if(desc) {
    let aDesc = desc.split(' ');
    if(aDesc.length > MAX_DESC_LENGTH) {
      desc = `${aDesc.slice(0, MAX_DESC_LENGTH).join(' ')}...`;
    }
  }

  return (
    <a href={`#${id}`} className={classes.link}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <Typography className={classes.title} variant="h5" component="h2">
            {title}
          </Typography>
          <Typography className={classes.desc}>{desc}</Typography>
          <div className={classes.tagContainer}>
            {tags.map((tag, idx) => (
              <Chip className={classes.tag} label={tag} key={idx}/>
            ))}
          </div>
        </CardContent>
      </Card>
    </a>
  );
}