import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import axios from 'axios';

import Button from './button';
import CKX from '../../assets/images/branding/ckxCircle.png';

const useStyles = makeStyles(theme => ({
    root: {
        overflow: 'hidden',
        width: '100%',
        minHeight: '100vh',
        padding:  theme.spacing(1),
        paddingBottom: theme.spacing(2),
        background: 'linear-gradient(to bottom, #BEEDFF, white)'
    },
    paper: {
        margin: `${theme.spacing(2)}px auto`,
        width: '100%',
        maxWidth: 800
    },
    title: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: 'white',
      display: 'inline-block'
    },
    logoContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    form: {
      width: '100%',
      padding: theme.spacing(2),
      minWidth: 200,
      maxWidth: 700,
      margin: '0 auto'
    },
    textInput: {
        width: '100%'
    },
    message: {
        width: '100%'
    }
}));

export default _ => {
    const classes = useStyles();
    const [submitting, setSubmitting] = React.useState('');
    const [name, setName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [message, setMessage] = React.useState('');

    const handleSubmit = _ => {
        if(submitting) {
            return;
        }
        setSubmitting(true);
        axios.post('https://hlkvdnwg0e.execute-api.eu-west-2.amazonaws.com/Prod/send', {
            fromEmail: email,
            name: name,
            message: message
        })
    }

    let btnDisabled = false;
    if(name === '' || email === '' || message === '') {
        btnDisabled = true;
    }
    return (
        <div className={classes.root}>
            <Paper className={classes.paper} id="contact-us">
                <Typography variant="h2" className={classes.title} gutterBottom>Contact Us</Typography>
                <div className={classes.logoContainer}>
                    <img src={CKX} alt="CKX Logo" />
                </div>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={e => setName(e.target.value)}
                                required
                                className={classes.textInput}
                                label="Name"
                                variant="outlined"
                                value={name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                onChange={e => setEmail(e.target.value)}
                                required
                                className={classes.textInput}
                                label="Email"
                                variant="outlined"
                                value={email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                onChange={e => setMessage(e.target.value)}
                                required
                                className={classes.message}
                                label="Message"
                                variant="outlined"
                                multiline
                                value={message}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <Button disabled={btnDisabled} onClick={handleSubmit}/>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </div>
    )
}