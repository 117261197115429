import React from 'react';

import BasicContent from '../../components/basicContent';

const CONFIG = {
    title: 'What Is CKX?',
    id: "what-is",
    content: [
        "We build bespoke artificial intelligence (AI), blockchain, internet of things (IOT) and serverless software solutions.",
        "We work with thinkers, makers, doers and innovators as your partner.",
        "We work on proof-of-concept projects to full turnkey solutions. From UK startups, Singapore Enterprise's, scale-up charities to governments"
    ]
}

export default _ => <BasicContent {...CONFIG}/>
