import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import VisibilitySensor from 'react-visibility-sensor';

import LinkedInIcon from '@material-ui/icons/LinkedIn';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minHeight: '100vh',
      backgroundAttachment: 'fixed',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      paddingBottom: theme.spacing(2),
      overflow: 'hidden'
    },
    contentRoot: {
        width: '100%',
        maxWidth: 1024
    },
    title: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        display: 'inline-block',
        transition: '500ms'
    },
    challenge: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        transition: '500ms'
    },
    desc: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        transition: '500ms'
    },
    notVisible: {
        margin: theme.spacing(0, 2),
        transition: '500ms'
    },
    article: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        backgroundColor: 'rgba(255,255,255,0.6)',
        backdropFilter: 'blur(5px)',
        borderRadius: theme.shape.borderRadius,
        display: 'inline-flex'
    },
    articleLink: {
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        textDecoration: 'none'
    },
    linkedIn: {
        textDecoration: 'none',
        color: '#0e76a8',
        fontSize: 32,
    },
    screenShotContainer: {
        padding: theme.spacing(2),
    },
    screenshot: {
        width: '100%'
    }
}))

export default ({title, bg, id, description, challenge, article, screenShots}) => {
    const classes = useStyles();
    let [visible, setIsVisible] = React.useState(false);
    return (
        <div id={id} className={classes.root} style={{backgroundImage: `url(${bg})`}}>
            <div className={classes.contentRoot}>
                <VisibilitySensor
                    delayedCall={2000}
                    onChange={setIsVisible}
                    partialVisibility={visible ? true : false}
                >
                    <div>
                    {title &&
                        <Typography variant="h2" className={`${classes.title} ${visible ? '' : classes.notVisible}`} gutterBottom>{title}</Typography>
                    }
                    {challenge &&
                        <Typography className={`${classes.challenge} ${visible ? '' : classes.notVisible}`}>{challenge}</Typography>
                    }
                    {description &&
                        <Typography className={`${classes.desc} ${visible ? '' : classes.notVisible}`}>{description}</Typography>
                    }
                    </div>
                </VisibilitySensor>
                {article &&
                        <div className={classes.article}>
                            <a href={article} target="_blank" rel="noopener noreferrer" className={classes.articleLink}>
                                <LinkedInIcon className={classes.linkedIn}/>
                            </a>
                        </div>
                }
                {screenShots &&
                        <Grid container spacing={3} className={classes.screenShotContainer}>
                            {screenShots.map(({src, label}, idx) => (
                                <Grid item xs={12} md={6} key={`${id}-screen-${idx}`}>
                                    <img src={src} className={classes.screenshot} alt={label}/>
                                </Grid>
                            ))}
                        </Grid>
                    }
            </div>
        </div>
    )
}