import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavigationIcon from '@material-ui/icons/Navigation';
import Fab from '@material-ui/core/Fab';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 200,
    padding: theme.spacing(2),
    position: 'fixed',
    top: theme.spacing(1),
    right: 0,
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    backgroundColor: 'rgba(0,0,0,0)',
    transitionDelay: '100ms',
    transition: 'background-color 100ms',
  },
  linksRoot: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0, 2),
    paddingTop: theme.spacing(1)
  },
  linksRootOpen: {
    backgroundColor: 'rgba(0,0,0,0.5)',
    transition: 'background-color 500ms',
  },
  btnClose: {
    transition: '100ms',
  },
  btnOpen: {
    transition: '500ms',
    transform: 'rotate(20deg)'
  },
  navButton: {
    margin: '0 auto'
  },
  link: {
    marginBottom: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'white',
    transition: 'background-color 100ms',
    "&:hover": {
        backgroundColor: "rgb(45,250,205)",
        transition: 'background-color 500ms',
    }
  }
}));

const LINKS = [
  {
    name: 'What is CKX?',
    href: '#what-is'
  },
  {
    name: 'Why Us?',
    href: '#why-us'
  },
  {
    name: 'Case Studies',
    href: '#case-studies'
  },
  {
    name: 'Contact CKX',
    href: '#contact-us'
  },
];

const ListItemLink = props => (
    <ListItem button component="a" {...props} />
)

export default function SpeedDials() {
  const classes = useStyles();
  let [usingTouch, setUsingTouch] = React.useState(false);
  let [hoverOpen, setHoverOpen] = React.useState(false);

  const show = useScrollTrigger({threshold: (window.innerHeight * 0.75), disableHysteresis: true});
  const scrollOpen = !useScrollTrigger({threshold: 0});

  if(hoverOpen && usingTouch) {
    setHoverOpen(false);
  }

  let open = scrollOpen || hoverOpen;

  return (
    <Fade in={show}>
        <div
          className={classes.root}
          onMouseOver={usingTouch ? null : _ => setHoverOpen(true)}
          onMouseOut={_ => setHoverOpen(false)}
          onTouchStart={_ => setUsingTouch(true)}
        >
            <Fab
                color="primary"
                aria-label="add"
                onClick={_ => window.scrollTo(0,0)}
                className={`${classes.navButton} ${open ? classes.btnOpen : classes.btnClose}`}
            >
                <NavigationIcon />
            </Fab>
            <div className={`${classes.linksRoot} ${open ? classes.linksRootOpen : ''}`}>
                <div>
                    <List component="nav" aria-label="main mailbox folders">
                        {LINKS.map(({name, href}, idx) => (
                            <Fade in={open} style={{transitionDelay: open ? `${100 + (idx*100)}ms` : '0ms'}} mountOnEnter unmountOnExit key={`nav-${idx}`}>
                                <div>
                                    <ListItemLink href={href} className={classes.link}>
                                        <ListItemText primary={name} />
                                    </ListItemLink>
                                </div>
                            </Fade>
                        ))}
                    </List>
                </div>
            </div>
        </div>
    </Fade>
  );
}
