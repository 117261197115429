import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import './button.scss'

const useStyles = makeStyles(theme => ({
    button: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary
    }
}));

export default ({disabled, onClick}) => {
    const classes = useStyles();
    const [isClicked, setIsClicked] = React.useState(false);
    const [isHover, setIsHover] = React.useState(false);
    const handleClick = e => {
        e.preventDefault();
        setIsClicked(true);
        onClick();
    }
    return (
        <button
            className={`${classes.button} button-bird ${isClicked ? 'active' : ''}  ${disabled ? 'disabled' : ''}`}
            onClick={handleClick}
            onMouseOver={_ => setIsHover(true)}
            onMouseOut={_ => setIsHover(false)}
            disabled={disabled}
        >
            <p className="button-bird__text">{`${isClicked ? 'SENT' : 'SEND'}`}</p>
            <svg version="1.1" className="feather" x="0px" y="0px"
                viewBox="0 0 75 38" style={{enableBackground:'new 0 0 75 38'}} >
                <g>
                    <path d="M20.8,31.6c3.1-0.7,2.9-2.3,2,1c9.1,4.4,20.4,3.7,29.1-0.8l0,0c0.7-2.1,1-3.9,1-3.9c0.6,0.8,0.8,1.7,1,2.9
                        c4.1-2.3,7.6-5.3,10.2-8.3c0.4-2.2,0.4-4,0.4-4.1c0.6,0.4,0.9,1.2,1.2,2.1c4.5-6.1,5.4-11.2,3.7-13.5c1.1-2.6,1.6-5.4,1.2-7.7
                        c-0.5,2.4-1.2,4.7-2.1,7.1c-5.8,11.5-16.9,21.9-30.3,25.3c13-4,23.6-14.4,29.1-25.6C62.8,9,55.6,16.5,44.7,20.7
                        c2.1,0.7,3.5,1.1,3.5,1.6c-0.1,0.4-1.3,0.6-3.2,0.4c-7-0.9-7.1,1.2-16,1.5c1,1.3,2,2.5,3.1,3.6c-1.9-0.9-3.8-2.2-5.6-3.6
                        c-0.9,0.1-10.3,4.9-22.6-12.3C5.9,17.7,11.8,26.9,20.8,31.6z"/>
                </g>
            </svg>
            <div className={isClicked ? 'showBirds': 'hideBirds'}>
                <span className="b bird"></span>
                <span className="b bird--1"></span>
                <span className="b bird--2"></span>
                <span className="b bird--3"></span>
                <span className="b bird--4"></span>
                <span className="b bird--5"></span>
                <span className="b bird--6"></span>
                <span className="b bird--7"></span>
                <span className="b bird--8"></span>
                <span className="b bird--9"></span>
                <span className="b bird--10"></span>
                <span className="b bird--11"></span>
                <span className="b bird--12"></span>
                <span className="b bird--13"></span>
                <span className="b bird--14"></span>
                <span className="b bird--15"></span>
                <span className="b bird--16"></span>
                <span className="b bird--17"></span>
                <span className="b bird--18"></span>
                <span className="b bird--19"></span>
                <span className="b bird--20"></span>
                <span className="b bird--21"></span>
                <span className="b bird--22"></span>
                <span className="b bird--23"></span>
                <span className="b bird--24"></span>
                <span className="b bird--25"></span>
                <span className="b bird--26"></span>
                <span className="b bird--27"></span>
                <span className="b bird--28"></span>
                <span className="b bird--29"></span>
            </div>
            <div className={isHover ? 'showBirds': 'hideBirds'}>
                <span className="b bird--30"></span>
            </div>
        </button>
    )
}