import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import VisibilitySensor from 'react-visibility-sensor';

import CKX from '../../assets/images/branding/CKX.png';

const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      minHeight: '100vh',
      backgroundImage: `url(${CKX})`,
      backgroundAttachment: 'fixed',
      backgroundPosition: 'bottom',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
      paddingBottom: theme.spacing(2),
      overflow: 'hidden'
    },
    contentRoot: {
        width: '100%',
        maxWidth: 700
    },
    title: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        display: 'inline-block'
    },
    section: {
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'white',
        transition: '500ms'
    },
    sectionHidden: {
        margin: theme.spacing(0, 2),
        transition: '500ms'
    }
}));


export default ({id, title, content}) => {
    const classes = useStyles();
    let [visible, setIsVisible] = React.useState(false);

    return (
        <div className={classes.root} id={id}>
            <div className={classes.contentRoot}>
                <Typography variant="h2" className={classes.title} gutterBottom>{title}</Typography>
                <VisibilitySensor
                    delayedCall={2000}
                    onChange={setIsVisible}
                    partialVisibility={visible ? true : false}
                >
                    <div>
                        {content.map((c, idx) => (
                            <Typography className={`${visible ? null : classes.sectionHidden} ${classes.section} `} key={`${id}-${idx}`}>{c}</Typography>
                        ))}
                    </div>
                </VisibilitySensor>
            </div>
        </div>
    )
}