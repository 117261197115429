import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';

import VisibilitySensor from 'react-visibility-sensor';

import SubjectSelect from './subjectSelect';
import CaseStudy from './caseStudy';
import Preview from './preview';

import CKX_X from '../../assets/images/branding/ckx_x.jpg';

/*global CASE_STUDIES */

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '100vh',
    backgroundImage: `url(${CKX_X})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: theme.spacing(2),
    overflow: 'hidden'
  },
  contentRoot: {
    width: '100%',
    maxWidth: 1024
  },
  gridRoot: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2)
  },
  title: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      backgroundColor: 'white',
      display: 'inline-block'
  },
  appBar: {
    backgroundColor: 'transparent'
  }
}));

export default _ => {
    const classes = useStyles();
    const [isVisible, setIsVisible] = React.useState(false);
    const [tagStatus, setTags] = React.useState({ai: true, serverless: true, blockchain: true, iot: true});

    const showPreview = serviceTags => {
        let showTags = [];
        Object.keys(tagStatus).forEach(key => {
            let value = tagStatus[key];
            if(value) showTags.push(key);
        });
        return showTags.some(r=> serviceTags.indexOf(r) >= 0)
    }

    return (
        <>
            <VisibilitySensor delayedCall={500} onChange={setIsVisible} partialVisibility={true} offset={{top:50,bottom:200}}>
                <div className={classes.root} id="case-studies">
                    <div className={classes.contentRoot}>
                        <Typography variant="h2" className={classes.title} gutterBottom>Case Studies</Typography>
                            <SubjectSelect onChange={setTags} isVisible={isVisible}/>
                        <Grid className={classes.gridRoot} container spacing={2}>
                            {CASE_STUDIES.map((cs, idx) => (
                                <Slide in={isVisible} key={idx} direction={idx % 2 ? 'left' : 'right'} style={{transitionDelay: isVisible ? `${1500+(idx*200)}ms` : '0ms'}}>
                                    <Fade in={showPreview(cs.tags)} style={{transitionDelay: 0}} mountOnEnter unmountOnExit>
                                        <Grid item>
                                            <Preview  {...cs}/>
                                        </Grid>
                                    </Fade>
                                </Slide>
                            ))}
                        </Grid>
                    </div>
                </div>
            </VisibilitySensor>
            {CASE_STUDIES.map((props, idx) => (
                <CaseStudy key={idx} {...props} />
            ))}
        </>
    );
}