import React from 'react';

import BasicContent from '../../components/basicContent';

const CONFIG = {
    title: 'Why Us?',
    id: "why-us",
    content: [
        "We are engineers. This makes us fast, versatile, creative and solutions-focussed, while robust in implementation.",
        "Our team of over 20 engineers, project managers, designers and developers are experts in their respective fields and create world class solutions.",
        "Head-quartered in London, we have a presence in South America.",
        "Because we believe in keeping everything simple.",
    ]
}

export default _ => <BasicContent {...CONFIG}/>